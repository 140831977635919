<template>
  <div>
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <h1 class="page-title">
      {{ $t('changelog') }}
    </h1>
    <!-- <b-card title="Version 1.91.0" class="mb-4">
      <b-card-text> <small>07.10.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Archiv-Modus
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Ticket Kategorie wird für Mandanten angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card> -->
    <b-card title="Version 1.90.2" class="mb-4">
      <b-card-text> <small>11.10.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Gutachten: Anordnung und Nummerierung der Variablen geändert.
          </li>
          <li>
            Terminslots: Darstellung der Kunden- und Mitarbeiternamen angepasst.
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            In der Projektliste und Einzelprojektansicht kann man nun auf die Adresse klicken um auf
            Google Maps zu gelangen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.90.1" class="mb-4">
      <b-card-text> <small>04.10.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Ticket Kategorie wird für Mandanten angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.90.0" class="mb-4">
      <b-card-text> <small>01.10.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mandant bearbeiten: Neuer Status "Aktiv" oder "Archiviert".
          </li>
          <li>
            Neue Logos.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.89.1" class="mb-4">
      <b-card-text> <small>27.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Ticket Kategorie: Neue Kategorie "Lieferrückstand", Kategorie "Cluster"
            entfernt.
          </li>
          <li>
            Projekte exportieren: Ticket Kategorie wird exportiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.89.0" class="mb-4">
      <b-card-text> <small>25.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalten "Übergabe" und "Ticket Kategorie".
          </li>
          <li>
            Projekt / Intern: Neue Auswahl "Ticket Kategorie".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Spalten bzgl. IN und NA ausgeblendet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.88.0" class="mb-4">
      <b-card-text> <small>20.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Qualitätsprotokoll Protokoll: neue Variablen hinzugefügt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Qualitätsprotokoll Protokoll: einige Variablen umbenannt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Zuordnung von Bildern im Qualitätsprotokoll korrigiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.87.0" class="mb-4">
      <b-card-text> <small>19.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Gutachten mit PDF-Generierung in der Einzelprojektansicht.
          </li>
          <li>
            Service Protokoll: neue Variablen hinzugefügt.
          </li>
          <li>
            Benutzer: Spaltenfilter hinzugefügt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: "Projekttyp" zeigt nun "N/A" an wenn nicht gesetzt.
          </li>
          <li>
            Projekt / Intern: Projekttyp bleibt als Standardwert nicht gesetzt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.86.0" class="mb-4">
      <b-card-text> <small>17.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Intern: Neuer Projektstatus "Projekttyp".
          </li>
          <li>
            Projekte: Neue Spalte "Projekttyp".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Login: E-Mail-Eingabe wird in Kleinbuchstaben konvertiert und es werden Leerzeichen
            entfernt.
          </li>
          <li>
            Benutzer bearbeiten: E-Mail-Eingabe wird in Kleinbuchstaben konvertiert und es werden
            Leerzeichen entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.85.2" class="mb-4">
      <b-card-text> <small>10.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Bei Projektansicht wurde der Tab "Aufmaß" zu "Aufmaßprotokoll" umbenannt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.85.2" class="mb-4">
      <b-card-text> <small>12.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Projektstatus "Planung Auftraggeber".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Dokumente: Mandanten haben nun Zugriff auf "Weitere Dokumente".
          </li>
          <li>
            Dashboard: HQC-Nummer wird auf den Karten "Neueste Aufgaben" und "Meine Aufgaben"
            angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.85.1" class="mb-4">
      <b-card-text> <small>11.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Bei der Aufgaben- und Projektansicht werden nun alle ausgewählten Filter vollständig
            entfernt, wenn eine Mehrfachauswahl getroffen wurde.
          </li>
        </ul>
        <ul>
          <li>
            Terminplanung: Start- und Enddatum bezieht den aktuellen Tag nun mit ein.
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            Das Copyright wurde auf Goaltip geändert.
          </li>
          <li>
            Aktivitätsprotokoll: Filter Datumsfilter
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.85.0" class="mb-4">
      <b-card-text> <small>05.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projektliste hat nun einen Filter für mehrere Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.84.0" class="mb-4">
      <b-card-text> <small>04.09.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Qualitätsprotokoll für Desktop-Nutzer hinzugefügt.
          </li>
        </ul>
      </b-card-text> </b-card
    ><b-card title="Version 1.83.1" class="mb-4">
      <b-card-text> <small>30.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Aktivitätsprotoll: Fehler bei Seitennavigation behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.83.0" class="mb-4">
      <b-card-text> <small>29.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            VLINK: Es werden nun Dateien aus VLINK importiert und unter "Projekte / Dokumente /
            VLINK Dokumente" abgelegt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.82.3" class="mb-4">
      <b-card-text> <small>28.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Aufgaben: Zuständige Benutzer werden nun mit Vorname und Nachname gefunden.
          </li>
          <li>
            Projektansicht: Kommunikation-mit-Auftraggeber-Textfeld läuft nicht mehr über.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.82.2" class="mb-4">
      <b-card-text> <small>27.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt - Angebote/Rechnungen: Neues Textfeld "AC - Weitere Positionen" mit Vorbelegung.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Übersicht: "Montage Termin" und "Montage Team" werden korrekt angezeigt
            (neuester Termin).
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.82.1" class="mb-4">
      <b-card-text> <small>13.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Abanhmeprotokoll: Doppelte und falsche Bennung wurde korrigiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.82.0" class="mb-4">
      <b-card-text> <small>09.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Abanhmeprotokoll: Neue Kategorien: HAK, Wallbox und Dokumente.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Abanhmeprotokoll: "Wechselrichter / Matebox / Speicher" umbenannt zu "Wechselrichter /
            Matebox / Speicher und diverse Durchbrüche". Ebenso wurden neue dieser Kategorie neue
            Unterpunkte hinzufügt.
          </li>

          <li>
            Abanhmeprotokoll:" Zählerschrank & GAK" umbenannt zu "Zählerschrank, GAK und diverse
            Durchbrüche". Ebenso wurden neue dieser Kategorie neue Unterpunkte hinzufügt.
          </li>
          <li>
            Abanhmeprotokoll: Bei Potential wurden Unterpunkte umbenannt.
          </li>
        </ul>

        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Abanhmeprotokoll: Fehlerhafte übergange wurden entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.81.1" class="mb-4">
      <b-card-text> <small>08.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Mandanten dürfen den Tab "EVU" sehen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.81.0" class="mb-4">
      <b-card-text> <small>07.08.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Aktivitätsprotoll
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Aufgaben: Mandanten-Benutzer können nun unter Zuständig zusätzlich zu Benutzern des
            eigenen Mandanten alle Admin-Benutzer sehen/zuordnen.
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.80.4" class="mb-4">
      <b-card-text> <small>26.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Aufgaben: Mandanten können nun zugeordnete Mitarbeiter sehen und selbst zuordnen.
          </li>
          <li>
            Dashboard: Meine Aufgaben hat nun einen Scrollbalken und zeigt mehr als 5 Aufgaben an.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt/Montage: Mandant sieht keine Aufgaben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.80.3" class="mb-4">
      <b-card-text> <small>24.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Material: Alles Material auf Typ "Standard" gesetzt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.80.2" class="mb-4">
      <b-card-text> <small>22.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dashboard / Termine: Neue Spalte "# Auftrag".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dashboard: Projekte mit Bestellliste nicht mehr leer (jetzt wirklich).
          </li>
          <li>
            Dashboard: Live updates für Termine temporär entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.80.1" class="mb-4">
      <b-card-text> <small>19.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dashboard: Projekte mit Bestellliste nicht mehr leer.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.80.0" class="mb-4">
      <b-card-text> <small>15.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projektexport nun auch für Mandanten und deren Projekte.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehlerfreies Laden der Benutzerdaten in der Bearbeitungsansicht.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.79.1" class="mb-4">
      <b-card-text> <small>11.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Die Terminanischt im Dashboard hat nun eine Datumsfilter (Maximale Zeitspanne ist vier
            Wochen).
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Termin Slots: Anzeige Numerische Teamnamen in den Slots.
          </li>
          <li>
            Termin Slots: Neue Slots werden oben angezeigt.
          </li>
          <li>
            Termin Slots: Grössenangleichung Slot-Termine.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.79.0" class="mb-4">
      <b-card-text> <small>11.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Das Dashboard hat nun eine Liste mit Projekten mit nicht-leeren Bestelllisten.
          </li>
          <li>
            Wenn man in der Bestellliste auf ein Projekt klickt, gelangt man direkt in die
            Projektansicht "Bestellungen".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobile Bestellungen: Alle Materialen werden angezeigt.
          </li>
          <li>
            Termin Slots / Termin bearbeiten: Es können nun Kunden aus beliebigen PLZ-Bereichen
            gefiltert und dem Slot zugeordnet werden.
          </li>
          <li>
            Termin Slots / Slots hinzufügen: PLZ entfernt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Nachrichten haben nun ein Sende Button.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.78.0" class="mb-4">
      <b-card-text> <small>08.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dashboard: Neue Karten "Neueste Aufgaben" und "Meine Aufgaben".
          </li>
          <li>
            Material Stammdaten: Spaltenfilter und Seitenaufteilung hinzugefügt.
          </li>
          <li>
            Projekt/Intern: Neue Textfelder "Frage an Auftraggeber" und "Antwort vom Auftraggeber".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Material Stammdaten: Fehler in der Weiterleitung zur Bearbeitungseite behoben.
          </li>
          <li>
            Aufgaben: Neu erstellte Aufgabe verschwindet nicht mehr nach dem ersten bearbeiten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.77.0" class="mb-4">
      <b-card-text> <small>04.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Material Stammdaten: Soft Delete von Materialen um Inkonsitenzen mit gelöschten
            Materialen zu vermeiden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.76.0" class="mb-4">
      <b-card-text> <small>03.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt/Bestellungen: Neuer Button "Bestellliste generieren" erstellt eine Excel
            Bestellliste.
          </li>
          <li>
            Aufgaben sichtbar für Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.75.3" class="mb-4">
      <b-card-text> <small>02.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            VLINK Import: Doppelte Projekte vermeiden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.75.2" class="mb-4">
      <b-card-text> <small>01.07.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt/Bestellungen: Neuer Button "Gesamte Auswahl löschen".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            VLINK-Import: Projekte werden jetzt nach Vorname, Nachname, PLZ Bestandsprojekten
            zugeordnet. Unabhängig vom Mandant.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.75.1" class="mb-4">
      <b-card-text> <small>28.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Tasks können nun von Rolle Admin gelöscht werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.75.0" class="mb-4">
      <b-card-text> <small>27.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Die Mobile Version hat nun einen neuen Tab "Bestellungen". Dieser Tab ist unterteilt in
            die Tabs: "Material Stammdaten" und "Für Projekt benötigtes Material".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Nutzer mit mehreren Mandanten können Projekte ihrerer zugeteilten Mandanten bearbeiten.
          </li>
          <li>
            Leerzeichen am Anfang und Ende eines Textfeldes werden nun beim Speichern automatisch
            etfernt bei: Mandant, Benutzer, Projekt/Kunde
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.74.2" class="mb-4">
      <b-card-text> <small>26.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Iranische Übersetzungen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.74.1" class="mb-4">
      <b-card-text> <small>21.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Änderung eines Prüfungstatus aktualisiert nicht mehr das ganze Projekt sondern
            nur das betreffende Feld.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.74.0" class="mb-4">
      <b-card-text> <small>14.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Prüfungsstatus "Service-Freigabe QCells/Auftraggeber" + Notizfeld
          </li>
          <li>
            Projekt: Neuer Prüfungsstatus "Abnahme-Freigabe QCells/Auftraggeber" + Notizfeld
          </li>
          <li>
            Projekt: Neuer Prüfungsstatus "Aufmaß-Freigabe QCells/Auftraggeber" + Notizfeld
          </li>
          <li>
            Projekt: Neuer Prüfungsstatus "Rechnungs-Freigabe QCells/Auftraggeber" + Notizfeld
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.73.1" class="mb-4">
      <b-card-text> <small>13.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Termin Slots: Es wurden max. 100 Slots angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.73.0" class="mb-4">
      <b-card-text> <small>12.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Benutzer können mehreren Mandanten zugeordnet werden.
          </li>
          <li>
            Überprüfungsstatus: Neuer Statuswert 'in Arbeit'
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.72.1" class="mb-4">
      <b-card-text> <small>04.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Tabs für Mandanten geändert: EVU deaktiviert, Bestellungen deaktiviert, Techn.
            Prüfung aktiviert, Angebote/Rechnungen aktiviert.
          </li>
          <li>
            Benutzer: Tabelle überarbeitet.
          </li>
          <li>
            Mandanten: Tabelle überarbeitet.
          </li>
          <li>
            Auftraggeber: Tabelle überarbeitet.
          </li>
          <li>
            Elektrofachkräfte: Tabelle überarbeitet.
          </li>
          <li>
            Messstellenbetreiber: Tabelle überarbeitet.
          </li>
          <li>
            Sidebar: Neuer Eintrag "Personalverwaltung" gruppiert zugriffsrelevante Views.
          </li>
          <li>
            Dashboard: Live Update Termine.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.72.0" class="mb-4">
      <b-card-text> <small>03.06.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile Projekte: Nachrichten hinzugefügt
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Autorisierung: Neue Rolle Gast.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.71.0" class="mb-4">
      <b-card-text> <small>22.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Abnahmeprotokoll: Module: Upload Foto von Typenschild
          </li>
          <li>
            Academy: Stundenplan & zugehörige Bearbeitungseite: Stundenplantermine
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Zurückspringen von Projektstatus "Netzanmeldung"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.70.0" class="mb-4">
      <b-card-text> <small>20.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dashboard Termine heute
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.69.0" class="mb-4">
      <b-card-text> <small>17.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Abnahmeprotokoll: Überprüfungsstatus "UK Prüfung"
          </li>
          <li>
            Projekt / Abnahmeprotokoll: Überprüfungsstatus "AC Prüfung"
          </li>
          <li>
            Projekt / Abnahmeprotokoll: Überprüfungsstatus "DC Prüfung"
          </li>
          <li>
            Projekt / Serviceprotokoll: Überprüfungsstatus "Service Prüfung"
          </li>
          <li>
            Projekt / Aufmaß: Überprüfungsstatus "Aufmaß Prüfung"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.68.0" class="mb-4">
      <b-card-text> <small>10.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Academy Page
          </li>
          <li>
            Neue Benutzerrolle: Schüler
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.67.0" class="mb-4">
      <b-card-text> <small>07.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Automatischer VLINK-Import.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.66.1" class="mb-4">
      <b-card-text> <small>02.05.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termin Slots: PLZ 0 hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.66.0" class="mb-4">
      <b-card-text> <small>22.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mandant: Neues Feld unter "E-Mail Zugangsdaten (SMTP)", "Antwort an (optional)"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.65.4" class="mb-4">
      <b-card-text> <small>17.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Bewerber: PLZ, Arbeitsbeginn & Telefonnummer hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.65.3" class="mb-4">
      <b-card-text> <small>17.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminslots: Initialien der zugewiesenen Mitarbeiter werden im Kalender angezeigt
          </li>
          <li>
            Projekt: Neuer Status "Netzanmeldung"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminslots: Start und Ende angepasst auf 7:30 - 17:30
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminslots: Kunde kann nun vom Termin entfernt werden
          </li>
          <li>
            Terminslots: Mitarbeiter / Fahrzeuge gehen verloren
          </li>
          <li>
            Terminslots: Termin wird am falschen Tag eingetragen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.65.2" class="mb-4">
      <b-card-text> <small>15.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminslots / Kunde auswählen: Filter nach "Projektstatus".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.65.1" class="mb-4">
      <b-card-text> <small>12.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile / Termine: Anzeige von "Anmerkungen DC" und "Anmerkungen AC".
          </li>
          <li>
            Terminslots: Bei Kundenauswahl wird "Dauer DC" angezeigt.
          </li>
          <li>
            Terminslots: Bei Kundenauswahl wird "Dauer AC" angezeigt.
          </li>
          <li>
            Terminslots: Bei Kundenauswahl wird "Anmerkungen DC" angezeigt.
          </li>
          <li>
            Terminslots: Bei Kundenauswahl wird "Anmerkungen AC" angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.65.0" class="mb-4">
      <b-card-text> <small>10.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminslots: Fahrzeuge und Mitarbeiter.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.64.0" class="mb-4">
      <b-card-text> <small>03.04.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Serviceprotokoll Generierung
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.63.1" class="mb-4">
      <b-card-text> <small>27.03.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Tab "Serviceprotokoll"
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Serviceprotokoll: Korrekte Felder bei "Erdungsanlage" eingefügt.
          </li>
          <li>
            Mobile Scanner: Kamera wird erst durch Buttonklick aktiviert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.63.0" class="mb-4">
      <b-card-text> <small>27.03.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile: Serviceprotokoll
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.62.0" class="mb-4">
      <b-card-text> <small>18.03.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Bearbeitungsstatus: Liste mit aktiven Benutzern.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.61.0" class="mb-4">
      <b-card-text> <small>15.03.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Bearbeitungsstatus.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.60.1" class="mb-4">
      <b-card-text> <small>01.03.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Bewerber Berechtigungen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.60.0" class="mb-4">
      <b-card-text> <small>29.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Zeiterfassungsübersicht hat Filter
          </li>
        </ul>
        <ul>
          <li>
            Mobile Zeiterfassung: Fehlerbehebung und Verbesserung des Nutzererlebnisses.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.59.1" class="mb-4">
      <b-card-text> <small>22.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Serverabsturz bei unterbrochenen Uploads behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.59.0" class="mb-4">
      <b-card-text> <small>19.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Bewerber: Neue Status, "Mitarbeiter" hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.58.0" class="mb-4">
      <b-card-text> <small>15.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Nachrichten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.57.0" class="mb-4">
      <b-card-text> <small>15.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            QPS-Branding
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.56.0" class="mb-4">
      <b-card-text> <small>13.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Bewerber: Neue Status, "Posten" und "Einsatzort" hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.55.1" class="mb-4">
      <b-card-text> <small>09.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminplanung: Nur aktive Mitarbeiter zur Auswahl anzeigen.
          </li>
          <li>
            Terminplanung: Es wurden einige Mitarbeiter nicht zur Auswahl angezeigt (Abruf war auf
            100 Mitarbeiter limitiert).
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.55.0" class="mb-4">
      <b-card-text> <small>07.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neuer Projektstatus "Service-Einsatz".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.54.1" class="mb-4">
      <b-card-text> <small>06.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            TEAM_AC Benutzer kann Termine nicht sehen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.54.0" class="mb-4">
      <b-card-text> <small>06.02.2024</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Material / Lager
          </li>
          <li>
            Terminplanung: Fahrzeuge zum Termin zuordnen.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Benutzer mit Rolle Mandant kann nicht zu einem Termin hinzugefügt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.5" class="mb-4">
      <b-card-text> <small>15.01.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Material: EAN-Nummern durch Import aktualisiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.4" class="mb-4">
      <b-card-text> <small>12.01.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobile: Zum Termin zugewiesene Mitarbeiter sehen eigene Termine unabhängig von der
            zugehörigkeit zum Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.3" class="mb-4">
      <b-card-text> <small>08.01.2024</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobile: Barcode Scanner, visuelle Rückmeldung.
          </li>
          <li>
            Mobile: Barcode Scanner sucht nun auch nach EAN-Nummern in Feld "Art. Nr. Industrie
            (EAN)".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.2" class="mb-4">
      <b-card-text> <small>22.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neue Status "Interne Reklamation" & "Externe Reklamation".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.1" class="mb-4">
      <b-card-text> <small>22.12.2023</small></b-card-text>

      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: Hausnummernzusatz anzeigen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.53.0" class="mb-4">
      <b-card-text> <small>21.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile: Neuer Tab "Material" mit Barcode Scanner.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.52.3" class="mb-4">
      <b-card-text> <small>18.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt/Bestellungen: Neues Feld um benutzerdefinierte Menge an Material hinzuzufügen
            oder zu entfernen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.52.2" class="mb-4">
      <b-card-text> <small>15.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Neuer Reiter "Bestellungen". Felder betreffend Material wurden aus "Techn.
            Prüfung" hier her verschoben.
          </li>
          <li>
            Projekt: Reiter "Angebot Q-Cells" entfernt. Angebotsgenerierung wurde in neuen Reiter
            "Bestellungen" integriert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.52.1" class="mb-4">
      <b-card-text> <small>13.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Alle Projekte: Zuordnung zu Material entfernt.
          </li>
          <li>
            Material: Löschen-Button entfernt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.52.0" class="mb-4">
      <b-card-text> <small>12.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neue Felder "Techn. Prüfung"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.51.3" class="mb-4">
      <b-card-text> <small>07.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "Verzögert".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Status lässt sich nicht auf "Installation" umstellen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.51.2" class="mb-4">
      <b-card-text> <small>06.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Status "Projekt verzögert".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.51.1" class="mb-4">
      <b-card-text> <small>04.12.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Projektstatus springt auf "Neukunde" beim speichern.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.51.0" class="mb-4">
      <b-card-text> <small>27.11.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Neuer Projektstatus "Techn. Vorprüfung"
          </li>
          <li>
            Projektstatus "Salescall 2" kommt nun nach "Techn. Vorprüfung"
          </li>
          <li>
            Mobile Ansicht: Team AC/DC Benutzer können nur noch auf Projekte zugreifen denen sie
            über einen Termin als Mitarbeiter zugeordnet wurden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.50.1" class="mb-4">
      <b-card-text> <small>24.11.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehler beim speichern von Projekten
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.50.0" class="mb-4">
      <b-card-text> <small>14.11.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Backend Framework Upgrade.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehler beim generieren von Abnahmeprotokoll/Aufmaßprotokoll.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.49.4" class="mb-4">
      <b-card-text> <small>14.11.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neuer Status: DC geprüft & Lead
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.49.3" class="mb-4">
      <b-card-text> <small>23.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehler beim zuordnen von EVU im Projekt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.49.1" class="mb-4">
      <b-card-text> <small>17.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile: Zeiterfassung und dazugehörige Übersicht
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.49.0" class="mb-4">
      <b-card-text> <small>16.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile: Einsatzplan
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.48.3" class="mb-4">
      <b-card-text> <small>12.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mitarbeiter: Einem Mitarbeiter / Team können nun Benutzeraccounts zugeordnet werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.48.2" class="mb-4">
      <b-card-text> <small>11.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehlerbehebung in Mobilansicht bei Kundenauswahl.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.48.1" class="mb-4">
      <b-card-text> <small>10.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Abnahmeprotokoll: Downloadfunktion "Bilder Abnahmeprotokoll". Fehlende Bilder
            Klemmbereiche, Abstand Traufe, First und Schienenüberstand werden nun ins ZIP-Archiv
            integriert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.48.0" class="mb-4">
      <b-card-text> <small>09.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Sobald Terminstatus auf Backlog oder Ersatztermin gesetzt wird erscheint
            ein Popup-Feld in dem die Statusänderung begründet werden muss.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.47.0" class="mb-4">
      <b-card-text> <small>06.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Abnahmeprotokoll: Downloadfunktion "Bilder Abnahmeprotokoll"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.6" class="mb-4">
      <b-card-text> <small>05.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projektphasen: Aufmaßnahme geändert in Aufmaß
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.5" class="mb-4">
      <b-card-text> <small>02.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: Farbliche Hervorhebung der Wochentage und Anzeige Wochentag in der
            "Start"-Spalte.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.4" class="mb-4">
      <b-card-text> <small>01.10.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projek / Montage: Bugfix Termine
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.3" class="mb-4">
      <b-card-text> <small>29.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: Listendarstellung überarbeitet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.2" class="mb-4">
      <b-card-text> <small>22.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termine: Neue Status: Sonstiges AC und Sonstiges DC
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.1" class="mb-4">
      <b-card-text> <small>22.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Filter für Mitarbeiter, Kunde und PLZ.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.46.0" class="mb-4">
      <b-card-text> <small>15.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neuer Funktion "Angebote exportieren"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.45.3" class="mb-4">
      <b-card-text> <small>12.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Dashboards aktualisiert
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.45.2" class="mb-4">
      <b-card-text> <small>11.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "# Auftrag".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.45.1" class="mb-4">
      <b-card-text> <small>07.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            TEAM_AC hat nun Zugriff auf alle Projekte in der mobilen Ansicht
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.45.0" class="mb-4">
      <b-card-text> <small>05.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neue Projektstatus Werte.
          </li>
          <li>
            Projekt: Fortschrittsanzeige Projektstatus.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.10" class="mb-4">
      <b-card-text> <small>04.09.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neuer Projekstatus "Zurück an Auftraggeber".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.9" class="mb-4">
      <b-card-text> <small>31.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termine: Beliebige Zeitspanne einstellen und Dashboard Projekte entfernt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.8" class="mb-4">
      <b-card-text> <small>31.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termine: Neuer Terminstatus "Storno".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.7" class="mb-4">
      <b-card-text> <small>14.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Projektstatus "8. Storno".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.6" class="mb-4">
      <b-card-text> <small>14.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dashboard Projekte: Neu!
          </li>
          <li>
            Dashboard Projekte: Termine in beliebigen Zeitraum anzeigen lassen
          </li>
          <li>
            Dashboard Projekte: Termine nach Status anzeigen lassen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.5" class="mb-4">
      <b-card-text> <small>01.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "Mobiltelefon"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.4" class="mb-4">
      <b-card-text> <small>28.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Termin Slots: Projektstatus wird beim bearbeiten eines Slots leer angezeigt und auch als
            leer abgespeichert.
          </li>
          <li>
            Termin Slots: Feld "Anmerkungen Techn. Prüfung" wird nach Auswahl des Projektes nicht
            sofort angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.3" class="mb-4">
      <b-card-text> <small>28.08.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Tabs Bennenungen geändert
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.2" class="mb-4">
      <b-card-text> <small>27.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Bewerber: Dokumentenupload.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.1" class="mb-4">
      <b-card-text> <small>26.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Bewerber: Autorisierung eingeschränkt auf eine Liste von Benutzern.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.44.0" class="mb-4">
      <b-card-text> <small>26.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neue Funktion: "Bewerber"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.43.4" class="mb-4">
      <b-card-text> <small>25.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termin Slots: Projektstatus kann unter "Termin bearbeiten" angepasst werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.43.3" class="mb-4">
      <b-card-text> <small>18.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termin Slots: Neuer Button "Termin bestätigen" für E-Mail-Versand.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Termin Slots: Zusätzliche Anzeige der Kundenfelder "Projekt #", "Telefon",
            "Mobiltelefon", "Anmerkungen Techn. Prüfung ".
          </li>
          <li>
            Termin Slots: Termindetails "Terminstatus" und "Bestätigt" können nun angepasst werden.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Termin Slots: Klick auf "Abbrechen" beim bearbeiten eines Termins setzt den
            entsprechenden Kalendereintrag zurück sofern noch nicht gespeichert wurde.
          </li>
          <li>
            Termin Slots: Button "Löschen" umbenannt in "Slot löschen" um die Funktion zu
            verdeutlichen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.43.2" class="mb-4">
      <b-card-text> <small>17.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte können bis auf Weiteres nicht mehr gelöscht werden.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminplanung: Mitarbeiter konnten nicht entfernt werden.
          </li>
          <li>
            Bildergalerien: Fotos teilweise abgeschnitten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.43.1" class="mb-4">
      <b-card-text> <small>14.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Termin Slots: Slots können gelöscht werden.
          </li>
          <li>
            Termin Slots: Kundensuche auf PLZ-Gebiet des Slots eingeschränkt.
          </li>
          <li>
            Termin Slots: Kundensuche sucht nach Vorname, Nachname, Ort, PLZ.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Termin Slots: Slots hinzufügen funktioniert nicht für einzelnen Tag.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.43.0" class="mb-4">
      <b-card-text> <small>13.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neues Feature: Termin Slots
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.42.0" class="mb-4">
      <b-card-text> <small>06.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Material: Bestand kann beim Material eingefplegt werden.
          </li>
          <li>
            Material: Hinzufügen/Entfernen von Material im Projekt verringert/erhöht den Bestand.
          </li>
          <li>
            Material: Preise ausgeblendet.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Dokumentennamen werden automatisch von Sonderzeichen gesäubert. Verhindert
            Probleme bei der Dokumentengenerierung.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.41.1" class="mb-4">
      <b-card-text> <small>03.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Fehler beim Filtern
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.41.0" class="mb-4">
      <b-card-text> <small>03.07.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Intern: Neuer Bereich für Aufgaben.
          </li>
          <li>
            Aufgaben: Filter für alle Spalten hinzugefügt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.40.0" class="mb-4">
      <b-card-text> <small>28.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neues Feature: Aufgaben
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.39.3" class="mb-4">
      <b-card-text> <small>23.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Abnahmeprotokoll: Beispielbilder
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.39.2" class="mb-4">
      <b-card-text> <small>23.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminplanung: Termine im Mandantenkontext werden nicht angezeigt.
          </li>
          <li>
            Projekt/Dokumente: Vorschau "Aufmaßprotokoll" war auf Vorschauseite "Abnahmeprotokoll"
            verlinkt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.39.1" class="mb-4">
      <b-card-text> <small>19.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Spalte "Termin" ebntfernt.
          </li>
          <li>
            Projekt/Dokumente: Rechte Spalte nun im gleichen Akkordeon-Format wie die linke Spalte.
          </li>
          <li>
            Projekt/Dokumente: Folgende Dokumente wurden hinzugefügt: "Angebot", "Aufmaßprotokoll",
            "Abnahmeprotokoll".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.39.0" class="mb-4">
      <b-card-text> <small>16.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Diverse Datenbankabfragen für Projekte und Termine optimiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.9" class="mb-4">
      <b-card-text> <small>14.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Mandanten sehen nun eine reduzierte Auswahl an Spalten.
          </li>
          <li>
            Projekte: Neue Spalten "Aufmaß Termin" und "Installation Termin" zeigen den aktuellsten
            Termin vom jeweiligen Typ.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.8" class="mb-4">
      <b-card-text> <small>14.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminplanung: Fehler beim anlegen von Terminen. Nicht mehr vorhandener Status "Offen"
            wurde als Standardwert verwendet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.7" class="mb-4">
      <b-card-text> <small>12.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Neuer Terminstatus "Team vor Ort".
          </li>
          <li>
            Projekte: Neue Spalte "Telefon".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: "Anlagenstatus" in "Terminstatus" umbenannt.
          </li>
          <li>
            Terminplanung: Terminstatus "Offen" in "Terminiert" umbenannt.
          </li>
          <li>
            Terminplanung: Spalten "Start" und "Ende" werden nun mit Datum und Uhrzeit angezeigt
            statt nur Uhrzeit.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.6" class="mb-4">
      <b-card-text> <small>02.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung für Mandanten freigeschaltet.
          </li>
          <li>
            Projekte: Neue Spalte "Telefon".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Spalte "Übergabe" entfernt.
          </li>
          <li>
            Projekt: Tab "Techn. Prüfung" für Mandanten ausgeblendet.
          </li>
          <li>
            Projekt: Tab "Aufmaß" für Mandanten freigeschaltet.
          </li>
          <li>
            Projekt: Tab "Abnahme" für Mandanten freigeschaltet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.5" class="mb-4">
      <b-card-text> <small>02.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt/Aufmaß: Ziegel Hersteller Auswahlfeld.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.4" class="mb-4">
      <b-card-text> <small>01.06.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt/Tech.Prüfung: Neues Datumsfeld "Material geliefert".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.3" class="mb-4">
      <b-card-text> <small>31.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Thumbnails Auflösung auf 512x512 vergrössert. Qualität 85%.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.2" class="mb-4">
      <b-card-text> <small>31.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Upgrade auf AWS-SDK v3.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Abnahme- und Inbetriebnahmeprotokoll: "2. Module" in "3. Module" geändert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.1" class="mb-4">
      <b-card-text> <small>30.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Rolle "Mandant" für mobile freigeschaltet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.38.0" class="mb-4">
      <b-card-text> <small>26.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile: Sprachauswahl.
          </li>
          <li>
            Übersetzungen in folgenden Sprachen hinzugefügt: englisch, italienisch, tschechisch,
            slovakisch, bosnisch, kroatisch, rumänisch, ungarisch, polnisch, bulgarisch, russisch,
            koreanisch
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Vorschaubilder: Qualität auf 70% (vorher 30%) eingestellt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.37.1" class="mb-4">
      <b-card-text> <small>19.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Aufmaßprotokoll PDF überarbeitet.
          </li>
          <li>
            Abnahmeprotokoll PDF überarbeitet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.37.0" class="mb-4">
      <b-card-text> <small>17.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Abnahmeprotokoll überarbeitet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.36.3" class="mb-4">
      <b-card-text> <small>12.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            PDF: Größe verringert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.36.2" class="mb-4">
      <b-card-text> <small>11.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Falsches IN-Datum in generierten Dokumenten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.36.1" class="mb-4">
      <b-card-text> <small>10.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Falsches IN-Datum in generierten Dokumenten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.36.0" class="mb-4">
      <b-card-text> <small>09.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Felder welche zum erfüllen der Vorraussetzungen für NA und IN notwendig sind
            werden markiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.35.0" class="mb-4">
      <b-card-text> <small>08.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Thumbnails: Automatische verkleinerung von hochgeladenen Bildern.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.16" class="mb-4">
      <b-card-text> <small>05.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "IN mögl.".
          </li>
          <li>
            EVU / Dokumente: Neues Feld "Notizen".
          </li>
          <li>
            Kalender: Neuer Menupunkt, Testversion.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.15" class="mb-4">
      <b-card-text> <small>02.05.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Aufmaß Bericht: "Foto Potentialausgleichschiene" fehlt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.14" class="mb-4">
      <b-card-text> <small>28.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Bilder aus Aufmaß integriert.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Dokumente: Bilder in Accordion-Tabs kategorisiert.
          </li>
          <li>
            Projekte: Spalte "Team" entfernt.
          </li>
          <li>
            Projekte: Spalte "Termin" entfernt.
          </li>
          <li>
            Projekte: Spalte "Inst. Art" entfernt.
          </li>
          <li>
            Projekte: Spalte "Inst. Art" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "Team" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "KW" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "Termin" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "Beginn" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "Montage-Zeit (h)" entfernt.
          </li>
          <li>
            Tourenplanung Liste: Spalte "Inst. Art" entfernt.
          </li>
          <li>
            Farben für Buttons und Hintergründe vereinheitlicht.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.13" class="mb-4">
      <b-card-text> <small>27.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Anlegen/Speichern von Projekten und Terminen schlägt fehl.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.12" class="mb-4">
      <b-card-text> <small>26.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Aufmaß und Mobile: Jeder Unterpunkt hat einen Status "Offen" /
            "Abgeschlossen". Status wird visuell dargestellt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.11" class="mb-4">
      <b-card-text> <small>25.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Timeout des Buttons beim Dokumente generieren verlängert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.10" class="mb-4">
      <b-card-text> <small>21.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Aufmaß: Bericht generieren.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.8" class="mb-4">
      <b-card-text> <small>20.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Tab "Aufmaß".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.7" class="mb-4">
      <b-card-text> <small>19.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            E-Mail: Vorlage "Terminbestätigung AM" angepasst.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.6" class="mb-4">
      <b-card-text> <small>18.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Mobile / Aufmaß: Leere Ansicht bei Aufmaß bei Neukunden. Wird nun korrekt initialisiert.
          </li>
          <li>
            Mobile / Aufmaß: Benutzer Team AC und Team DC durften nicht speichern.
          </li>
          <li>
            Berechtigungen: Benutzer Team AC und Team DC sehen nur Projekte des zugewiesenen
            Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.5" class="mb-4">
      <b-card-text> <small>17.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile-Erkennung: Nach Login wird je nach Gerät auf die Dekstop- oder Mobile-Version
            weitergeleitet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.4" class="mb-4">
      <b-card-text> <small>14.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Projektstatus "7. Abgerechnet".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: Versenden der Bestätigungsmail setzt den Status "Bestätigt" nicht mehr
            auf "Ja".
          </li>
          <li>
            Projekt / Voraussetzungen NA: Felder "Ortsteil" und "Flur" von der Prüfung ausgenommen.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Terminplanung: Terminbestätigung per E-Mail wählt nun die korrekte Vorlage aus.
          </li>
          <li>
            Projekt / E-Mail: Button "Terminbestätigung" nicht mehr deaktiviert.
          </li>
          <li>
            PDF-Erstellung: Potententielles Memory Leak behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.3" class="mb-4">
      <b-card-text> <small>06.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Fehler "Invalid Date" bei Feld "IN - Ort, Datum"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.2" class="mb-4">
      <b-card-text> <small>03.04.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Fehler beim speichern eines neuen Projektes behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.1" class="mb-4">
      <b-card-text> <small>31.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Dokumente: Übersichtsschaltbild wird nicht generiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.34.0" class="mb-4">
      <b-card-text> <small>31.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: IN-Datum wurde nicht korrekt angezeigt.
          </li>
          <li>
            Datenbank: Kai Putz aus den Projekten von SOMV Project entfernt.
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobil: Neuer Tab 'Aufmaß'.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.33.4" class="mb-4">
      <b-card-text> <small>21.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Neue Spalte "# Module".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.33.3" class="mb-4">
      <b-card-text> <small>20.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Techn. Prüfung: Materialliste generieren.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.33.2" class="mb-4">
      <b-card-text> <small>17.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "Material vorhanden"
          </li>
          <li>
            Projekt / Techn. Prüfung: Neues Feld "Material vorhanden"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Material: Tab entfernt.
          </li>
          <li>
            Projekt / Techn. Prüfung: Materialplanung eingefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.33.1" class="mb-4">
      <b-card-text> <small>16.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor: "Notstromsystem" Intelligente Checkboxen.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Montage: "Termin Inbetriebnahme" in eigentständigen Datumswert umgewandelt.
          </li>
          <li>
            Projekt / Projektstatus: "Voraussetzungen IN" auf "Termin Inbetriebnahme" reduziert.
          </li>
          <li>
            Projekt / Montage: "Alte" Installationstermine entfernt.
          </li>
          <li>
            Editor: Eindeutigere Bennenungen für Ja/Nein-Checkboxen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.33.0" class="mb-4">
      <b-card-text> <small>14.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Tab "Angebot Q-Cells"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.6" class="mb- 4">
      <b-card-text> <small>08.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobile: Beschleunigung Kundenauswahl.
          </li>
          <li>
            Terminplanung: Filter werden gespeichert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.5" class="mb-4">
      <b-card-text> <small>06.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Anzeige Anzahl Einträge und Zeitraum in Header und Footer.
          </li>
          <li>
            Terminplanung: Filter Terminart.
          </li>
          <li>
            Terminplanung: Filter Bestätigt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Konfiguration: Modul Gesamtleistung zeigt nun 3 Dezimalstellen.
          </li>
          <li>
            EVU: Filter für Firma und EVU finden nun vorkommen der Suchanfrage innerhalb des
            kompletten Feldinhaltes (nicht nur am Anfang).
          </li>
          <li>
            Terminplanung: Standardauswahl im Kalenderfilter auf 14 Tage geändert.
          </li>
          <li>
            Terminplanung: Kalenderfilter kann gelöscht werden um alle (!) Termine anzuzeigen.
          </li>
          <li>
            Terminplanung: Untermenu in Navigation entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.4" class="mb-4">
      <b-card-text> <small>03.03.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Liste kann nach Anlagenstatus gefiltert werden.
          </li>
          <li>
            Terminplanung: Termine können kopiert werden. Der bestehende Termin wird auf
            'Ersatztermin' gesetzt.
          </li>
          <li>
            Terminplanung: Neuer Anlagenstatus 'Ersatztermin'.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Montage: Status "Bestätigt" konnte nicht ausgewählt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.3" class="mb-4">
      <b-card-text> <small>27.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Montage: Installationstermine nun synchron mit Terminplanung.
          </li>
          <li>
            Terminplanung: Neue Terminart "Sonstiges"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.2" class="mb-4">
      <b-card-text> <small>24.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Termin löschen.
          </li>
          <li>
            Terminplanung: Termin per E-Mail bestätigen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.1" class="mb-4">
      <b-card-text> <small>21.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Terminplanung: Termine aus Projekten (ab 24.02.2023) übernommen.
          </li>
          <li>
            Terminplanung: Neuer Termintyp "AC / DC"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Terminplanung: Mitarbeiterauswahl.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.32.0" class="mb-4">
      <b-card-text> <small>21.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Neuer Download "Netzanmeldung kombiniert"
          </li>
          <li>
            Terminplanung: Planungskalender (beta)
          </li>
          <li>
            Neuer Menupunkt "Mitarbeiter"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.5" class="mb-4">
      <b-card-text> <small>16.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Export: Es wird nun der letzte Montagetermin im Export ausgegeben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.3" class="mb-4">
      <b-card-text> <small>31.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Techn. Prüfunfg: Neues Feld "Ware bestellt".
          </li>
          <li>
            Editor / Kunde / Persönliche Daten: Neues Feld "Vorname Nachname" (ohne Komma).
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Konfiguration / Einspeisemanagement: Wert "Keines" als Standardwert
            festgelegt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.4" class="mb-4">
      <b-card-text> <small>02.02.2023</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Abnahmeprotokoll: Fehler beim generieren des Dokumentes.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.3" class="mb-4">
      <b-card-text> <small>31.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Techn. Prüfunfg: Neues Feld "Ware bestellt".
          </li>
          <li>
            Editor / Kunde / Persönliche Daten: Neues Feld "Vorname Nachname" (ohne Komma).
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Konfiguration / Einspeisemanagement: Wert "Keines" als Standardwert
            festgelegt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.1" class="mb-4">
      <b-card-text> <small>25.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Voraussetzungen NA: Projektbeschreibung entfernt.
          </li>
          <li>
            Projekt / Voraussetzungen NA: Elektrofachkraft hinzugefügt.
          </li>
          <li>
            Projekt / Voraussetzungen NA: Übersichtsschaltbild hinzugefügt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Filter "Ort" funktioniert nun.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.2" class="mb-4">
      <b-card-text> <small>27.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Konfiguration / Einspeisemanagement: Neuer Wert "Keines".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.1" class="mb-4">
      <b-card-text> <small>25.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Voraussetzungen NA: Projektbeschreibung entfernt.
          </li>
          <li>
            Projekt / Voraussetzungen NA: Elektrofachkraft hinzugefügt.
          </li>
          <li>
            Projekt / Voraussetzungen NA: Übersichtsschaltbild hinzugefügt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Filter "Ort" funktioniert nun.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.31.0" class="mb-4">
      <b-card-text> <small>17.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Neue Kategorien "Übersichtsschaltbild (automatisch generiert)" und
            "Übersichtsschaltbild (hochgeladen)". Dokumente beider Kategorien werden beim versenden
            von Netzanmeldungen per E-Mail mitgeschickt.
          </li>
          <li>
            Editor: Neuer Dokumententyp "Übersichtsschaltbild"
          </li>
          <li>
            Editor: Neue Bedingungen für Dokumentengenerierung, "Haussicherung 35A", "Haussicherung
            50A", "Haussicherung 63A".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.30.3" class="mb-4">
      <b-card-text> <small>10.01.2023</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Montage: "Termin per E-Mail bestätigen" für Mandanten freigeschaltet.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Fehlende Mandanten-Projektnummern für Mandant SSP.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.30.1" class="mb-4">
      <b-card-text> <small>13.12.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt /EVU Anmeldung: Bestehende Anmeldungen verlinken über die Spalte "Externe
            Referenz" zum Bayernwerk Portal. Eine vorherige Anmeldung im Bayernwerk Portal ist
            Vorraussetzung damit die Verlinkung funktioniert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.30.2" class="mb-4">
      <b-card-text> <small>19.12.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Text Erstinfo
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.30.1" class="mb-4">
      <b-card-text> <small>13.12.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt /EVU Anmeldung: Bestehende Anmeldungen verlinken über die Spalte "Externe
            Referenz" zum Bayernwerk Portal. Eine vorherige Anmeldung im Bayernwerk Portal ist
            Vorraussetzung damit die Verlinkung funktioniert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.30.0" class="mb-4">
      <b-card-text> <small>12.12.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: EVU Anmeldung.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.29.4" class="mb-4">
      <b-card-text> <small>06.12.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Übersicht: Projekt-Export reaktiviert.
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt-Export: Ausgabe Mandanten-Daten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.29.3" class="mb-4">
      <b-card-text> <small>08.11.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Elektrofachkraft: Fehler bei Neuanlage einer Elektrofachkraft.
          </li>
          <li>
            Projekt: Datumseingabe bei Neuanlage bleibt leer.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Übersicht: Anzeige vollständige Adresse, zweizeilig.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.29.2" class="mb-4">
      <b-card-text> <small>11.10.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Löschen von Dokumenten führt zum Verlust von ungespeicherten Änderungen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.29.1" class="mb-4">
      <b-card-text> <small>06.10.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Konfiguration: Wechselrichter Leistung zeigt nun "Nennleistung" anstatt
            "Wirkleistung".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Elektrofachkraft / EVU-Dropdown: Suchfunktion aktiviert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.29.0" class="mb-4">
      <b-card-text> <small>05.10.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Elektrofachkräfte: Gasteintragungsnummer kann pro EVU eingetragen werden.
          </li>
          <li>
            Editor: Neues Feld "Elektrofachkraft -> Gasteintragungsnummer".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.28.4" class="mb-4">
      <b-card-text> <small>26.09.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Alle Projekte ohne Mandant wurden dem Mandant "SSP" zugeordnet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.28.3" class="mb-4">
      <b-card-text> <small>26.09.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            EVU's: Globaler Filter durch Spaltenfilter ersetzt.
          </li>
          <li>
            Projekte und Tourenplanung: Filter "Auftraggeber" zeigt nun nur Auftraggeber des
            ausgewählten Mandanten. Ist kein Mandant ausgewählt ist der Filter gesperrt.
          </li>
          <li>
            Tourenplanung: Filter "Zähler gewechselt" entfernt.
          </li>
          <li>
            "Tourenplanung" in "Tourenplanung Liste" umbenannt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.28.2" class="mb-4">
      <b-card-text> <small>22.09.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Filter defekt / Leere Liste.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.28.1" class="mb-4">
      <b-card-text> <small>21.09.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Breadcrumb-Navigation verlinkt zur alten Version der Projektliste.
          </li>
          <li>
            Editor: Filter defekt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.28.0" class="mb-4">
      <b-card-text> <small>19.09.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Listenansicht beschleunigt (wird Serverseitig gefiltert).
          </li>
          <li>
            Tourenplanung: Listenansicht beschleunigt (wird Serverseitig gefiltert).
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.7" class="mb-4">
      <b-card-text> <small>30.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            EVU: Neuer Menupunkt "EVU" für Mandanten. Mandanten sehen nur EVU's welche "NA Auto"
            und/oder "IN Auto" auf "Ja" gesetzt haben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.6" class="mb-4">
      <b-card-text> <small>18.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor/Allgemein: Neues Feld "geplanter IN Termin" (20 Tage ab jeweils heutigem Datum).
          </li>
          <li>
            Editor/Anlage/Wechselrichter 1: Neues Feld "Integrierter NA-Schutz U> 253 V".
          </li>
          <li>
            Editor/Intelligente Checkboxen/Leistungsangaben: Neuer Subordner "kWp".
          </li>
          <li>
            Editor/Intelligente Checkboxen/Leistungsangaben: Neue Felder im Ordner "kWp"
          </li>
          <li>
            Editor/Intelligente Checkboxen/Leistungsangaben: Neuer Subordner "Batteriespeicher".
          </li>
          <li>
            Editor/Intelligente Checkboxen/Leistungsangaben: Neue Felder im Ordner
            "Batteriespeicher"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Wechselrichter + Editor: Feld in "Integrierter NA-Schutz U> X*Un" umbenannt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.5" class="mb-4">
      <b-card-text> <small>15.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "NA Auto"
          </li>
          <li>
            Projekte: Neue Spalte "IN Auto"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.4" class="mb-4">
      <b-card-text> <small>06.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Fehler beim berechnen der E-Mail-Größen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.3" class="mb-4">
      <b-card-text> <small>05.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Angebote/Rechnungen: Notizfeld.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / EVU: Text "SSP bei ZW dabei?" geändert in "Installateur bei ZW dabei?".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.2" class="mb-4">
      <b-card-text> <small>04.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Falsche Ausgabe "Anzahl Baugleich".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.1" class="mb-4">
      <b-card-text> <small>01.08.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt/Dokumente: Fehler beim hochladen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.27.0" class="mb-4">
      <b-card-text> <small>30.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Buttonleiste (Speichern, Speichern und zurück, Abbrechen, usw.) in allen
            Bearbeitungsansichten verinheitlicht!
          </li>
          <li>
            Projekt: Neuer Tab "Angebote/Rechnungen"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.26.0" class="mb-4">
      <b-card-text> <small>26.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt/Kunde: neues Feld "Hausnummer Zusatz"
          </li>
          <li>
            Editor/Kunde/Persönliche Daten: Neue Felder "Hausnr_Zusatz", "Hausnr Hausnr_Zusatz",
            "Straße Hausnr Hausnr_Zusatz", "Straße Hausnr Hausnr_Zusatz, PLZ Ort"
          </li>
          <li>
            Wallboxen: Neues Feld ""Verschiebungsfaktor cos phi""
          </li>
          <li>
            Editor / Intelligente Checkboxen / Wallbox: Neue Felder "Verschiebungsfaktor cos phi -
            induktiv", "Verschiebungsfaktor cos phi - kapazitiv"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Editor/Anlage/Modul/"Wirkleistung Pav,e (70%) kWp": Stellt nur einen Wert dar, wenn
            unter Projekte/Konfiguration im Feld Einspeisemanagement "Dauerhafte Begrenzung" UND im
            Feld Dauerhafte Begrenzung "70%" ausgewählt sind.
          </li>
          <li>
            Navigation / Anlage : "Speichersysteme" ans Ende der Liste verschoben und in
            "Speichersysteme (alpt)" umbenannt.
          </li>
          <li>
            Editor / Kunde / Persönliche Daten: Feld "Straße Hausnr, PLZ Ort" entfernt.
          </li>
          <li>
            Editor / Kunde / Persönliche Daten: Feld ""Straße Hausnummer"" entfernt.
          </li>
          <li>
            Editor / Kunde / Persönliche Daten: Folgende Felder werden in allen Dokumenten auf die
            entsprechende Variante mit Hausnr_Zusatz umgestellt: "Hausnummer", "Straße Hausnummer",
            "Straße Hausnr, PLZ Ort".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Konfiguration: Einheit bei Wechselrichter von "kWp" in "kW" geändert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.25.3" class="mb-4">
      <b-card-text> <small>21.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Tab "Techn. Prüfung" für Mandanten freigeschaltet.
          </li>
          <li>
            Projekt: Popup Vorraussetzungen für Mandanten aktiviert.
          </li>
          <li>
            Projekt / E-Mail : Textanpassunge "Erstinfo".
          </li>
          <li>
            Projekt: Uploads für Mandanten freigeschaltet ("Bilder vom Auftraggeber","Bilder
            Aufmaß","Bilder Team DC","Bilder Team AC","Projektbeschreibung").
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Button "Termin per E-Mail bestätigen" landet im falschen Tab.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.25.2" class="mb-4">
      <b-card-text> <small>19.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Fehler beim Projekte generieren
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.25.0" class="mb-4">
      <b-card-text> <small>15.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Intelligente Cheboxen / Leistungsangaben: Neue Checkboxen "bis 7,69 kWp","bis
            >7,69 kWp bis 10 kWp","über 10 kWp".
          </li>
          <li>
            Mandant: Neues Feld "E-Mail Kopie an (optional)"
          </li>
          <li>
            Projekte: Neuer Filter "Mandant"
          </li>
          <li>
            Projekte (als Mandant): Neuer Filter "Auftraggeber"
          </li>
          <li>
            Editor: Neue Spalte "Beschreibung" in der Dokumentenliste
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.24.0" class="mb-4">
      <b-card-text> <small>14.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Eigene Projektnummern für Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.23.0" class="mb-4">
      <b-card-text> <small>12.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mandant: E-Mail Signatur
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.22.2" class="mb-4">
      <b-card-text> <small>12.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Fehler wenn nach Auftraggeber gefiltert wird.
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            E-Mail Versand: Zugangsdaten werden nun beim Mandant hinterlegt. Der Versand aus dem
            Projekt heraus erfolgt im Kontext des dem jeweiligen Projekt zugeordneten Mandanten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.22.1" class="mb-4">
      <b-card-text> <small>11.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: Daten von Elektrofachkraft wurde nicht korrekt angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.22.0" class="mb-4">
      <b-card-text> <small>08.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mandanten
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.21.2" class="mb-4">
      <b-card-text> <small>07.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Intern: Bereich "Abschluss" ausgeblendet.
          </li>
          <li>
            Projekt / Montage: Felder "LAN Verkabelung notwendig", "Powerline notwendig", "Speicher
            und Batterie vor Ort" ausgeblendet.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Automatische Zuordnung von "Auftraggeber" und "Elektrofachkraft" entfernt da
            sich die verfügbaren Werte je nach Kontext unterscheiden.
          </li>
          <li>
            Editor: Fehler bei fehlenden Daten bei der Elektrofachkraft.
          </li>
          <li>
            Editor: Geburtstag zeigt "Invalid Date" wenn nicht ausgefüllt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.21.1" class="mb-4">
      <b-card-text> <small>01.07.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: E-Mail-Tab für Auftraggeber deaktiviert
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.21.0" class="mb-4">
      <b-card-text> <small>30.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Zugang für Auftraggeber
          </li>
          <li>
            Auftraggeber: Zuordnung Elektrofachkräfte
          </li>
          <li>
            Projekt / Montage: Auswahl Elektrofachkraft zeigt nur dem Projekt-Auftraggeber
            zugeordnete Elektrofachkräfte.
          </li>
          <li>
            Projekt / E-Mail: Für Auftraggeber freigeschaltet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.20.2" class="mb-4">
      <b-card-text> <small>24.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: "Anlage v2" umbenannt in "Konfiguration"
          </li>
          <li>
            Editor / EVU: Felder "Messstellenbetreiber - Name" und "Messstellenbetreiber - Dritter"
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor / Intelligente Checkboxen / Batteriespeicher: Fehlerhafte Anzeige behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.20.1" class="mb-4">
      <b-card-text> <small>10.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Editor / Anlage : Anzahl "0" wird bei Batteriespeicher, Wallbox und Wechselrichter nun
            als "" (leer) angezeigt.
          </li>
          <li>
            Editor / EVU: Felder "Messstellenbetreiber - Name" und "Messstellenbetreiber - Dritter"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.20.0" class="mb-4">
      <b-card-text> <small>10.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Intelligente Checkboxen / Wallbox: Neue Felder "Wirkleistung steuerbar - Ja",
            "Wirkleistung steuerbar - Nein".
          </li>
          <li>
            Editor / Intelligente Checkboxen / Messstellenbetreiber: Neue Felder "MSB EVU", "MSB
            Dritter"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Editor / EVU / Messstellenbetreiber: Ist EVU als Messstellenbetreiber ausgewählt wird
            kein Wert angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.19.0" class="mb-4">
      <b-card-text> <small>09.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Kunde / Liegenschaft: Neues zusammengesetztes Feld "Ortsteil/Flurstück"
          </li>
          <li>
            Editor / Intelligente Checkboxen / Wallbox: Neue Felder "Ja", "Nein,", "Art der Ladung
            AC", "Art der Ladung DC".
          </li>
          <li>
            Editor / Intelligente Checkboxen / Wallbox: Neue Felder "Anschluss - Wechselstrom",
            "Anschluss - Drehstrom,", "Anschluss - L1", "Anschluss - L1", "Anschluss - L3". Bei
            Auswahl "Drehstrom" werden alle Checkboxen L1,L2,L3 aktiviert.
          </li>
          <li>
            Editor / Anlage / Wallbox: Neue Felder "Anzahl Wallboxen", "Anzahl Ladepunkte".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Elektrofachkraft: Bearbeitungsseite nicht aufrufbar.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.18.0" class="mb-4">
      <b-card-text> <small>03.06.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Wirkleistung pro Phase".
          </li>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Wirkleistung Phase 1 / 2 / 3".
          </li>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Nennleistung Phase 1 / 2 / 3".
          </li>
          <li>
            Editor / Anlage /Intelligente Checkboxen: "Wechselrichter 2 -> Ja".
          </li>
          <li>
            Editor / Anlage /Intelligente Checkboxen: "Batteriespeicher -> Nein".
          </li>
          <li>
            Editor / Anlage /Intelligente Checkboxen: "Netzeinspeisung -> 3 phasig/Drehstrom".
          </li>
          <li>
            Projekt Anlage v2 & Editor / Anlage / Weiterführende Konfiguration: "Messkonzept".
          </li>
          <li>
            Projekt: Tab "Enpal Bericht" in "Abnahmeprotokoll" geändert.
          </li>
          <li>
            Editor / Stammdaten: Notizenfeld.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.17.0" class="mb-4">
      <b-card-text> <small>25.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Messstellenbetreiber in Projekt/EVU und Editor.
          </li>
          <li>
            Editor / Anlage / Weiterführende Konfiguration: "Anlagenart Zahlenwert A".
          </li>
          <li>
            Editor / Anlage / Weiterführende Konfiguration: "Anlagenart Zahlenwert B".
          </li>
          <li>
            Editor / Anlage / Weiterführende Konfiguration: "Anlagenart Zahlenwert C".
          </li>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Zusammengesetzter Hersteller - Typ".
          </li>
          <li>
            Anlage v2: Standardwerte
          </li>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Zusammengesetzter Hersteller - Typ".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Anlage v2 / Editor: Leere Wallbox oder EnFluRi verursachen Fehler im Dokument wenn
            "Hersteller - Typ" verwendet wird.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.16.1" class="mb-4">
      <b-card-text> <small>25.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Zusammengesetzter Hersteller" getrennt
            durch "/". Bei baugleichen Wechselrichtern wird der Hersteller nur einmal angezeigt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Editor / Anlage / Wechselrichter Allgemein: "Zusammengesetzter Typ". Bei baugleichen
            Wechselrichtern wird der Typ nur einmal angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.16.0" class="mb-4">
      <b-card-text> <small>24.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Stammdaten: Neue Auswahl "Bedingung für Dokumentengenerierung". Steuert ob das
            Dokument beim generieren von Netzanmeldungen und Inbetriebnahmemeldungen berücksichtigt
            wird.
          </li>
          <li>
            Editor / Liste: Neue Spalte "Bedingung für Dokumentengenerierung"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.7" class="mb-4">
      <b-card-text> <small>20.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor / Anlage / Wechselrichter 1 & 2: Anzahl.
          </li>
          <li>
            Editor / Intelligente Cheboxen / Einspeiseart: "Dauerhafte Begrenzung auf 70% - Ja",
            "Dauerhafte Begrenzung auf 70% - Nein"
          </li>
          <li>
            Editor / Intelligente Cheboxen / Einspeisemanagement: "Überschusseinspeisung Ja/Nein",
            "Volleinspeisung Ja/Nein"
          </li>
          <li>
            Projekt / Anlage v2 : Infobox zur Anschlussart bei Batteriespeicher
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Anlagen / Batteriespeicher: Inselbetrieb aus "Anschlussart" entfernt.
          </li>
          <li>
            Projekt Anlage und Editor: Speicherkopplung entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.6" class="mb-4">
      <b-card-text> <small>16.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor: "Wechselrichter Allgemein" -> "Anzahl baugleich"
          </li>
          <li>
            Editor: "Intelligente Checkboxen" -> "Zählerbefestigung"
          </li>
          <li>
            Editor: "Intelligente Checkboxen" -> "Batteriespeicher"
          </li>
          <li>
            Editor: "Intelligente Checkboxen" -> "Speicherkopplung"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Anlage v2: Wert in "Dauerhaft Begrenzung" wird gelöscht wenn bei
            "Einspeisemanagement" "FRE" ausgewählt wird.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.5" class="mb-4">
      <b-card-text> <small>14.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Project Anlage V2 / Editor / Intelligente Checkboxen: "Anlagenart", "Inselbetrieb
            vorgesehen","Motorischer Anlauf","Einspeiseart"
          </li>
          <li>
            Editor: "Weiterführende Konfiguration" -> "Haussicherung"
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor: WR2: Nennleistung zeigt keinen Wert im Formular
          </li>
          <li>
            Editor / Wechselrichter Allgemein: "Anzahl" ohne Nachkommastellen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.4" class="mb-4">
      <b-card-text> <small>13.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Batteriespeicher (Anlage und Editor), neue Felder: "Bemessungsspannung" , "Nutzbare
            Speicherkapazität", "Anschlussart"
          </li>
          <li>
            Editor / Intelligente Checkboxen: "Batteriespeicher Anschlussart"
          </li>
          <li>
            Wechselrichter (Anlage und Editor), neue Felder: "Integrierter NA-Schutz U> (V)" ,
            "NA-Schutz nach VDE-AR-N 4105"
          </li>
          <li>
            Editor / Intelligente Checkboxen: "Wechselrichter NA-Schutz"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.3" class="mb-4">
      <b-card-text> <small>11.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Editor: Neue Kategorie "Anlage -> Wechselrichter Allgemenin" mit den Feldern "Anzahl
            Wechselrichter", "Zusammengesetzter Typ", "Scheinleistung Gesamt", "Wirkleistung Gesamt"
            , "Nennleistung Gesamt", "Nennleistung pro Phase"
          </li>
          <li>
            Editor: Neue Felder in "Anlage -> Wechselrichter" (1 und 2)" - "Nennleistung",
            "Nennspannung"
          </li>
          <li>
            Editor: Neues Feld in "Intelligente Checkboxen" - "dauerhafte Begrenzung - Nein"
          </li>
          <li>
            Anlage / Wechselrichter: Neue Felder "Nennleistung", "Nennspannung"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>Navigation: "Dokumente" in "Editor" umbenannt.</li>
          <li>
            Editor: "Anlage -> Batteriespeicher" - "Anzahl" gibt 0 oder 1 aus, je nachdem ob ein
            Batteriespeicher zugeordnet ist.
          </li>
          <li>
            Editor: "Anlage -> Speichersystem" in "Anlage -> Speichersytem (alt)" umbenannt
          </li>
          <li>
            Editor: "Anlage -> Weiterführende Konfiguration -> "Dauerhafte Begrenzung" in "Anlage ->
            Weiterführende Konfiguration -> "Dauerhafte Begrenzung in %" umbenannt
          </li>
          <li>
            Editor: Bezeichnungen unter "Intelligente Checkboxen -> Einspeisemanagement" angepasst.
            "FRE - Ja", "FRE - Nein", "dauerhafte Begrenzung - Ja"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.2" class="mb-4">
      <b-card-text> <small>09.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente / Intelligente Checkboxen: Einspeisemanagement und dauerhafte Begrenzung
          </li>
          <li>
            Dokumente / Intelligente Checkboxen: Netzeinspeisung 1phasig, 2 phasig, 3 phasig.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Anlage v2: Netzeinspeisung in Mehrfachauswahl geändert.
          </li>
          <li>
            Dokumente / Intelligente Checkboxen: Unterkategorie pro Feld.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.15.0" class="mb-4">
      <b-card-text> <small>04.05.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Anlage v2: Formular fertig gestellt
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Die E-Mail "Netzanmeldung" werden nun Dateien angehängt wie unter
            "Anlage v2" konfiguriert. Die zur alten Anlagenkonfiguration zugehörigen Dokumente
            werden nicht mehr angehängt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.14.1" class="mb-4">
      <b-card-text> <small>28.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Bericht wird als Word-Dokument (.docx) generiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.14.0" class="mb-4">
      <b-card-text> <small>27.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Bericht wird als Word-Dokument (.docx) generiert.
            Vorschauversion, noch nicht alle Felder enthalten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.13.0" class="mb-4">
      <b-card-text> <small>19.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Anlagen / Wallboxen
          </li>
          <li>
            Projekte / Anlage v2: Vorschau der Neuerungen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.12.1" class="mb-4">
      <b-card-text> <small>11.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Bildauswahl über Galerieansicht für alle Felder aktiviert.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Fehler wenn keine Modulzuordnung im Projekt vorhanden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.12.0" class="mb-4">
      <b-card-text> <small>08.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Bildauswahl über Galerieansicht.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.12" class="mb-4">
      <b-card-text> <small>07.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Darstellungsfehler Seite 7 behoben.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Hochgeladene Signatur wird nur angezeigt wenn keine "echte"
            Unterschrift aus der Mobilansicht hochgeladen wurde.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.11" class="mb-4">
      <b-card-text> <small>07.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Alle mit "Ja/Nein" zu beantwortenden Textfelder wurden in
            Ja/Nein-Auswahlfelder umgewandelt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.10" class="mb-4">
      <b-card-text> <small>06.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht / Abnahmeprotokoll: "Die Einweisung des Kunden hat
            stattgefunden" ist nun ein Ja/Nein-Auswahlfeld.
          </li>
          <li>
            Projekt / Enpal Bericht / Montagedokumentation - 3. Module: Kasten mit "Anlagendetails"
            hizugefügt.
          </li>
          <li>
            Solarmodul(e) in Modul(e) umbenannt (global im ganzen Tool).
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.9" class="mb-4">
      <b-card-text> <small>05.04.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Dokumente: Hochladen mehrerer Dokumente löste Folgefehler im Enpal Bericht
            aus.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht / Montagedokumentation - Technische Anlagedaten: Anlagtyp in
            Auswahlfeld geändert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.8" class="mb-4">
      <b-card-text> <small>30.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Enpal Bericht: Vorschaufunktion
          </li>
          <li>
            Projekt / Enpal Bericht: Zuordnung von hochgeladenen Unterschriften zu Abnahme- und
            Inbetriebnahmeprotokoll.
          </li>
          <li>
            Projekt / Dokumente: jfif-Format wird als Bild angezeigt.
          </li>
          <li>
            Projekt / Dokumente: Neue Kategorie "Unterschrift".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.7" class="mb-4">
      <b-card-text> <small>30.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht: Neue Felder
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.6" class="mb-4">
      <b-card-text> <small>29.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht: Bei allen Bild-Feldern können nun immer auch Bilder aus den Kategorien
            "Team AC" und "Team DC" ausgewählt werden.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Enpal Bericht: Eingabe von Kommazahlen bei "Gerüst" -> "Traufhöhe" erzeugte Fehler.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.5" class="mb-4">
      <b-card-text> <small>25.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projektzugriff Enpal
          </li>
          <li>
            Enpal Bericht: Grössere Bilder in Dropdownboxen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.4" class="mb-4">
      <b-card-text> <small>17.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Beim Versand von Inbetriebnahme und Netzanmeldung werden nun immer
            sowohl hochgeladene als auch generierte Dokumente angehängt. Die Auswahlmöglichkeit
            wurde entfernt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.3" class="mb-4">
      <b-card-text> <small>16.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht: Mobile und Desktop Version, alle Felder integriert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.2" class="mb-4">
      <b-card-text> <small>15.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht: neue Felder.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.1" class="mb-4">
      <b-card-text> <small>15.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht: neue Felder.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Mobilansicht: Unterschriften wurden nicht angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.11.0" class="mb-4">
      <b-card-text> <small>14.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neuer Menupunkt: Beispielbilder für Berichte hochladen.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobilansicht: Benutzeroberfläche mit Tabs.
          </li>
          <li>
            Mobilansicht: Alle Benutzerrollen können alle Bildtypen (Team AC, Team DC, Vorschäden,
            Aufmaß) hochladen und einsehen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.10.0" class="mb-4">
      <b-card-text> <small>08.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Enpal Bericht aktualisiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.10" class="mb-4">
      <b-card-text> <small>07.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Fehler beim speichern eines neuen Projektes.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Bei neuen Projekten wird "Enpal" als Auftraggeber voreingestellt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.9" class="mb-4">
      <b-card-text> <small>04.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Enpal Bericht: Work in progress.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.8" class="mb-4">
      <b-card-text> <small>02.03.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Enpal Bericht: erste Demo Version.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.7" class="mb-4">
      <b-card-text> <small>10.02.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Elektrofachkraft: Neues Feld "Hausnummer"
          </li>
          <li>
            Dokument/bearbeiten: Dokumentennr anzeigen.
          </li>
          <li>
            Editor/Kunde/Persönlich Daten: Neues Feld "Straße Hausnr, PLZ Ort".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Editor/Montage: Die Gruppe in Elektrofachkraft umbenennen
          </li>
          <li>
            Editor/Elektrofachkraft: Feld "Termin Inbetriebnahme" in "IN-Datum" umbenennen und in
            Editor/Allgemein verschieben
          </li>
          <li>
            Editor/Elektrofachkraft: Feld "Elektrofachkraft – Vorname Nachname" in "Vorname
            Nachname" umbenennen.
          </li>
          <li>
            Editor/Elektrofachkraft: Neues Feld "Straße" und neues Feld "Hausnr".
          </li>
          <li>
            Editor/Elektrofachkraft: neues Feld "Straße Hausnr" als zusammengesetzter Wert aus
            Straße und Hausnr.
          </li>
          <li>
            Editor/Anlage/Solarmodul: Leistung pro Modul (Wp) ohne Kommastellen.
          </li>
          <li>
            Editor/Allgemein - Feld "Datum" in "NA-Datum" umbenennen.
          </li>
          <li>
            Editor/Allgemein: Feld "Unterschrift" in "Kunde Unterschrift" umbenennen.
          </li>
          <li>
            Editor/EVU: Feld "Straße" in "Straße Hausnr" umbenennen
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Editor/Kunde/Bank, Steuer: Klick auf Eintrag markiert nicht mehr alle Felder.
          </li>
          <li>
            Editor: Verschobene Elemente.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.6" class="mb-4">
      <b-card-text> <small>27.01.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Kunde : Neue Felder "Zählernummer 1 Notizen", "Zählernummer 2 Notizen" und
            "Zählernummer 3 Notizen".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dokumente generieren: Fehler beim generieren bei Speichersystemen ohne Batteriespeicher.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.5" class="mb-4">
      <b-card-text> <small>25.01.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt/Dokumente: Galleriebilder werden nach Name aufsteigend sortiert.
          </li>
          <li>
            Mobilansicht Projekte: Es werden alle Kunden angezeigt (nicht nur EON).
          </li>
          <li>
            Dokument bearbeiten: Seitennavigation zeigt Gesamtanzahl Seiten an (z.B. Seite 1 / 2)
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokument bearbeiten: Druckvorschau
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.4" class="mb-4">
      <b-card-text> <small>12.01.2022</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Batteriespeicher bearbeiten: "Speicherkapazität" wird nun mit 3 Dezimalstellen
            formatiert angezeigt.
          </li>
          <li>
            Batteriespeicher Liste: Alle Spalten mit Dezimalstellen werden auf 3 Dezimalstellen
            formatiert angezeigt.
          </li>
          <li>
            Folgende Werte werden bei Dokumentendruck ohne Komma dargestellt: "Anzahl
            Batteriespeicher", "Anzahl Wechselrichter". "Anzahl Batteriemodule", "Anzahl EnFluRi",
            "Anzahl Solarmodule".
          </li>
          <li>
            Textbezeichner: "Anzahl Energieflussrichtungssensoren" umbenannt in "Anzahl EnFluRi".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Sollte ein Fehler bei der Genierung von Netzanmeldungen oder
            Inbetriebnahmemeldungen auftreten kann durch speichern des Projektes der Status
            zurückgesetzt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.3" class="mb-4">
      <b-card-text> <small>11.01.2022</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente: Neuer Status "in Arbeit" für Feld "Freigegeben"
          </li>
          <li>
            Dokumente: Filtereinstellungen werden gespeichert
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.2" class="mb-4">
      <b-card-text> <small>21.12.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente: Neues Feld "Freigegeben"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.1" class="mb-4">
      <b-card-text> <small>14.12.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Batteriespeicher: Neue Felder "Max. Entladeleistung (kW)", "Anzahl Batteriemodule",
            "Speicherkapazität je Batteriemodul (kWh)".
          </li>
          <li>
            Dokumenteneditor: Neue Felder für Batteriespeicher ("Max. Entladeleistung (kW)", "Anzahl
            Batteriemodule", "Speicherkapazität je Batteriemodul (kWh)").
          </li>
          <li>
            Wechselrichter: Neue Felder "Bemessungsstrom (A)", "Kurzschlussstrom (A)", "Nom. DC
            Leistung (kW)", "Zul. Lade/Entladestrom (A)".
          </li>
          <li>
            Dokumenteneditor: Neue Felder für Wechselrichter "Bemessungsstrom (A)",
            "Kurzschlussstrom (A)", "Nom. DC Leistung (kW)", "Zul. Lade/Entladestrom (A)".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.9.0" class="mb-4">
      <b-card-text> <small>10.12.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neues Feature: Angebote
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.10" class="mb-4">
      <b-card-text> <small>30.11.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente: Felder löschen mit "entf"-Taste auf der Tastatur.
          </li>
          <li>
            Dokumente: Neuer Feldtyp "Freitextfeld".
          </li>
          <li>
            Dokumente: Neuer Feldtyp "Anlage" -> "Wechselrichter" -> "Wirkl./Phase".
          </li>
          <li>
            Projekt/Montage: Kai Putz wird als Standardauswahl bei "Elektrofachkraft" vorbelegt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.9" class="mb-4">
      <b-card-text> <small>05.11.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt/Dokumente: ZIP-Downloads.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.8" class="mb-4">
      <b-card-text> <small>03.11.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Zufällige logouts behoben.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.7" class="mb-4">
      <b-card-text> <small>02.11.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dokumentengenerator: Nicht ausgefüllte Projektfelder verursachen keine Fehlermeldung
            mehr. Die Ausgabe der betroffenen Felder bleibt leer.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.6" class="mb-4">
      <b-card-text> <small>01.11.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dokumentengenerator: Fehlender 'Termin Inbetriebnahme' löst Fehler beim generieren aus.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Montage: Termin Inbetriebnahme kann wieder entfernt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.5" class="mb-4">
      <b-card-text> <small>22.10.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Jede Dokumentenkategorie hat nun einen Downloadbutton über welchen
            alle Dokumente der jeweiligen Kategorie heruntergeladen werden können.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.4" class="mb-4">
      <b-card-text> <small>20.10.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dokumente: Beim löschen von Elementen verschieben sich andere ELemente.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.3" class="mb-4">
      <b-card-text> <small>13.10.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobilansicht: Team DC sieht nun "Bilder Aufmaß"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.2" class="mb-4">
      <b-card-text> <small>05.10.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente: Neues Kombinationsfeld "Kunde" -> "Persönliche Daten" -> "Vorname, Nachname".
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt/Montage: "Termin per E-Mail bestätigen" springt auf falschen Tab.
          </li>
          <li>
            Dokumente: Löschen von Feldern bringt die Postion der Felder durcheinander.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.1" class="mb-4">
      <b-card-text> <small>01.10.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Dokumenteneditor: Feld "NA - Ort, Datum" geändert auf Erstellungsdatum der Datei (vorher
            "Übergabedatum"). Bezeichnung geändert in "NA - Ort, Datum (Erstellungszeitpunkt)".
          </li>
          <li>
            Dokumenteneditor: Bezeichnung geändert "IN - Ort, Datum" in "IN - Ort, Datum (Termin
            Inbetriebnahme)".
          </li>
          <li>
            Material-Feature in Menu und Projekansicht wieder eingeblendet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.8.0" class="mb-4">
      <b-card-text> <small>30.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Inbetriebnahmemeldungen automatisch generieren.
          </li>
          <li>
            Dokumente: Neues Feld Dokumententyp (Netzanmeldung, Inbetriebnahme, Sonstiges)
          </li>
          <li>
            Dokumente können 1 oder mehreren EVU's zugeordnet werden
          </li>
          <li>
            EVU: Dokumente für Netzanmeldung und Inbetriebnahmemeldung werden nicht mehr direkt
            bearbeitet sondern aus bestehenden Dokumenten zugeordnet.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.11" class="mb-4">
      <b-card-text> <small>24.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neues Feld unter Montage: "Ursprüngliche Installationsart"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.10" class="mb-4">
      <b-card-text> <small>23.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Mobile Ansicht: PDF's öffnen nicht auf IOS-Geräten.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.9" class="mb-4">
      <b-card-text> <small>20.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobile Ansicht: Upload und Galerie für "Bilder Aufmaß" (nur Team AC).
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte und Tourenplanung: Termin wird farbocodiert: Grün = Termin bestätigt, Rot =
            Termin nicht bestätigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.8" class="mb-4">
      <b-card-text> <small>15.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / E-Mail: Wenn "Automatisch generierte Netzanmeldung-Dokumente anhängen" gewählt
            wird, werden zusätztlich die manuell hochgeladenen Dokumente der Kategorie
            "Netzanmeldung" angehängt.
          </li>
          <li>
            Projekt / Dokumente: Automatisch generierte Netzanmeldungen können einzeln gelöscht
            werden.
          </li>
          <li>
            Projekte: neue Spalte "kWp"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.7" class="mb-4">
      <b-card-text> <small>10.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Dokumente / EVU: Die Uploadfunktion akzeptiert nur noch Bilddateien.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Netzanmeldung mit Option "Automatisch generierte
            Netzanmeldung-Dokumente anhängen" funktioniert nun.
          </li>
          <li>
            EVU: Uploadfeld Netzanmeldungsdokumente wurde nicht korrekt zurückgesetzt nach Upload.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.6" class="mb-4">
      <b-card-text> <small>09.09.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Dokumentengenerator: Unterschriften und Stempel werden in einer passenden Größe (Höhe
            60px für Unterschriften, Höhe 80px für Stempel) auf der Dokumentenvorlage platziert.
          </li>
          <li>
            Dokumentengenerator: Schriftgröße reduziert
          </li>
          <li>
            Dokumentengenerator: Position der platzierten Elemente stimmt nun besser mit
            Ausgabeposition überein.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Automatisch genererierte Dokumente wurden nicht fertig und verursachten
            Folgefehler.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.5" class="mb-4">
      <b-card-text> <small>30.08.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Neue Felder unter Projekt / Kunde: "E-Mail 2", "Mobiltelefon 2", "Notizen".
          </li>
          <li>
            Dokumentenupload für EVU. Dokumente werden unter Projekt/EVU angezeigt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Spalte "Erstinfo ges" zwischen "Ort" und "EVU verschoben.
          </li>
          <li>
            Projekte: Spalte "IN möglich" entfernt.
          </li>
          <li>
            Projekt: Vor einem Upload wird das Projekt nun gespeichert damit noch ungespeicherte
            Änderungen am Projekt nicht mehr verloren gehen.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>Leere Projektansicht wenn kein EVU zugeordnet</li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.4" class="mb-4">
      <b-card-text> <small>25.08.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "Erstinfo ges.".
          </li>
          <li>
            Dokumentenupload für EVU. Dokumente werden unter Projekt/EVU angezeigt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Spaltenkopf "Übergabedatum" in "Übergabe" umbenannt.
          </li>
          <li>
            Projekte: Spalte "ZW" verschoben.
          </li>
          <li>
            Projekt / Dokumente: Dokumentenlisten werden alphabetisch sortiert
          </li>
          <li>
            Speichersysteme: Neue Tabellenansicht
          </li>
          <li>
            Batteriespeicher: Neue Tabellenansicht
          </li>
          <li>
            Wechselrichter: Neue Tabellenansicht
          </li>
          <li>
            Module: Neue Tabellenansicht
          </li>
          <li>
            EnFluRi: Neue Tabellenansicht
          </li>
          <li>
            EVU: Neue Tabellenansicht
          </li>
          <li>
            Auftraggeber: Neue Tabellenansicht
          </li>
          <li>
            Elektrofachkräfte: Neue Tabellenansicht
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: PLZ filtert nun so dass die PLZ mit dem eingebenen Wert beginnen muss.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.3" class="mb-4">
      <b-card-text> <small>04.08.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobilansicht Projekte: Neuer Bereich "Bilder vom Auftraggeber".
          </li>
          <li>
            EVU Stammdaten: Neues Feld "Website".
          </li>
          <li>
            Benutzer: Neues Feld "Firma".
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Textanpassungen E-Mail-Vorlagen
          </li>
          <li>
            Benutzerliste: Neu organisiert
          </li>
          <li>
            Benutzer: Benutzer können nun gelöscht werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.2" class="mb-4">
      <b-card-text> <small>03.08.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobilansicht Projekte: Nur Projekte vom Auftraggeber "EON" werden in der Liste
            angezeigt.
          </li>
          <li>
            Mobilansicht Projekte: Überschriften zu allen Karten hinzugefügt.
          </li>
          <li>
            Mobilansicht Projekte: Telefonnummern hinzugefügt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Mobilansicht Projekte: Suche hatte auf einigen Geräten aufgrund Groß-/Kleinschreibung
            nicht funktioniert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.1" class="mb-4">
      <b-card-text> <small>29.07.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobilansicht Projekte: Dokumentenkategorie Projektbeschreibung hinzugefügt.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Mobilansicht Projekte: Formatierung Auswahlliste (beginnt mit Nachname), Sortierung nach
            Nachname.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.7.0" class="mb-4">
      <b-card-text> <small>28.07.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Mobiler Bildupload für Benutzer mit der Berechtigung "Team AC" und "Team DC". Die
            zugeordneten Benutzer können nur die Mobilansicht mit der Uploadfunktion aufrufen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.20" class="mb-4">
      <b-card-text> <small>16.07.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Bei "Termin per E-Mail bestätigen" wird nun die korrekte
            E-Mail-Vorlage ausgewählt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.19" class="mb-4">
      <b-card-text> <small>09.07.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Vorraussetzungen NA hinzugefügt: Zählernummer 1, Flur, Flurstück, Gemarkung
          </li>
          <li>
            Projekt / Vorraussetzungen NA entfernt: Auftraggeber, Projektnummer (Auftraggeber)
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.18" class="mb-4">
      <b-card-text> <small>06.07.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          Projekt / Montage: Neues Feld "Material bestellt".
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          Projekt / Dokumente: Fehler beim gleichzeitigen Upload mehrerer Dateien behoben.
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.17" class="mb-4">
      <b-card-text> <small>23.06.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          Projekt / E-Mail: E-Mail Vorlagen werden nach dem Speichern aktualisiert (nicht erst nach
          dem erneuten Laden der Seite).
        </ul>
        <ul>
          Projekt / E-Mail: Der Nachname wird nicht mehr in die Vorlage eingefügt wenn unter Kunde
          "Firma" ausgewählt wird (es wird nur noch 'Sehr geehrte Damen und Herren' eingefügt).
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.16" class="mb-4">
      <b-card-text> <small>22.06.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Erfolgs- und Fehlermeldungen werden beim speichern angezeigt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          Projekt: Vorraussetzungen im Projektstatus werden aktualisiert wenn ein Lageplan
          hochgeladen wurde.
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.15" class="mb-4">
      <b-card-text> <small>18.06.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / E-Mail: Eigentstädige Textvorlagen für "Terminbestätigung ZW"
            "Terminbestätigung AM" und "Terminbestätigung D, UV, ZS, NB".
          </li>
          <li>
            Projekt / E-Mail: Auswahl "E-Mail-Vorlage" alphabetisch sortiert.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Intern: EON als Auftraggeber für neue Projekte vorbelegt.
          </li>
          <li>
            Projekt / Dokumente: Sobald ein Dokumententyp Lageplan hochgeladen wurde, wird unter
            Projekt/Intern "Lageplan" auf "Erhalten" gesetzt.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Intern: Auswahl Auftraggeber wurde erst beim Speichern aktualisiert.
          </li>
          <li>
            Projekt / E-Mail: Empfänger bleibt leer solange kein EVU ausgewählt wurde.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.14" class="mb-4">
      <b-card-text> <small>17.06.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / E-Mail: "Erstinfo", Text, Signatur und Absender angepasst. 2 PDF-Dateien
            werden immer angehängt ("Informationsschreiben gem. §25Abs.4 MaStRV.pdf" und
            "MaStR_Flyer.pdf").
          </li>
          <li>
            Projekt / E-Mail: "Fehlende Unterlagen", Signatur und Absender angepasst.
          </li>
          <li>
            Projekt / E-Mail: "Inbetriebnahme", Signatur angepasst.
          </li>
          <li>
            Projekt / E-Mail: "Netzanmeldung", Signatur angepasst.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.13" class="mb-4">
      <b-card-text> <small>04.06.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Fehler beim Versand von Netzanmeldungen wenn kein Speichersytem
            ausgewählt.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.12" class="mb-4">
      <b-card-text> <small>31.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            EVU: Neues Auswahlfeld "SSP bei ZW dabei?". Wird zusätztlich unter Projekt / EVU
            angezeigt.
          </li>
          <li>
            Projekt / EVU: Neues Textfeld "Notizen" für EVU.
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Vor dem Senden von Netzanmeldung und Inbetriebnahme wird nicht mehr
            geprüft ob "Anmeldung per E-Mail" im EVU auf "Ja" steht.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.11" class="mb-4">
      <b-card-text> <small>26.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Manuelle Eingabe von Datumswerten in Datumsfeldern ermöglicht.
          </li>
          <li>
            Projekt: Monats-/Jahresauswahl in Datumsfeldern.
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Montage: 15-Minuten-Schritte funktionieren nun in Auswahl "Montagebeginn".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.10" class="mb-4">
      <b-card-text> <small>25.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Texte aktualisiert für "Fehlende Unterlagen", "Netzanmeldung",
            "Inbetriebnahme"
          </li>
          <li>
            Projekt / E-Mail: E-Mail-Signatur enthält nun Absenderadresse.
          </li>
          <li>
            Projekt / Anlage: Auswahl Speichersystem und Solarmodul kann entfernt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.9" class="mb-4">
      <b-card-text> <small>21.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / E-Mail: Die Größe der Anhänge für die jeweilige E-Mail wird vor dem versenden
            angezeigt.
          </li>
          <li>
            Projekt: Ausgewählter Projektstatus wird in der Karte "Projektstatus" angezeigt.
          </li>
          <li>
            Projekt / Montage: Auswahl "Installationstermin" setzt "Montagebeginn" auf 08:00.
          </li>
          <li>
            Projekt / Montage: Auswahl "Installationstermin" aktualisiert "Kalenderwoche".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.8" class="mb-4">
      <b-card-text> <small>20.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Filter für "Zähler gewechselt"
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Montage: Datum "Zähler gewechselt am" kann gelöscht werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.7" class="mb-4">
      <b-card-text> <small>18.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Netzanmeldung wird nun trotz fehlender Dokumente verschickt
          </li>
        </ul>
        <h5>Neu</h5>
        <ul>
          <li>
            Anlage / Speichersystem: Suchfunktion für Auswahl "Wechselrichter" aktiviert
          </li>
          <li>
            Anlage / Speichersystem: Suchfunktion für Auswahl "Batteriespeicher" aktiviert
          </li>
          <li>
            Anlage / Speichersystem: Suchfunktion für Auswahl "Energieflussrichtungssensor"
            aktiviert
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Absenderadresse und BCC-Adresse für die E-Mail "Fehlende Unterlagen"
            geändert auf "a.traunfellner@ssp-elektro.com"
          </li>
          <li>
            Projekt: Datumsfelder -> Woche fängt mit Montag an
          </li>
          <li>
            Projekt / Speichersystem: Auswahl "Wechselrichter" alpabethisch sortiert
          </li>
          <li>
            Projekt / Speichersystem: Auswahl "Batteriespeicher" alpabethisch sortiert
          </li>
          <li>
            Projekt / Speichersystem: Auswahl "Energieflussrichtungssensor" alpabethisch sortiert
          </li>
          <li>
            Projekte: Breite Kalenderfilter in Spalten "Übergabedatum" und "Termin" angepasst
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.6" class="mb-4">
      <b-card-text> <small>14.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Suchfunktion für Auswahl "Dokumententyp" aktiviert
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Dokumente: Auswahl "Dokumententyp" alpabethisch sortiert
          </li>
          <li>
            Projekt / E-Mail: Absenderadresse und BCC-Adresse für die E-Mails "Inbetriebnahme" und
            "Netzanmeldung" geändert auf "a.traunfellner@ssp-elektro.com"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.5" class="mb-4">
      <b-card-text> <small>12.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Bei Netzanmeldung fehlen Anhänge vom Wechselrichter
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.4" class="mb-4">
      <b-card-text> <small>10.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Neuer Dokumententyp "Bilder Aufmaß".
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.3" class="mb-4">
      <b-card-text> <small>07.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Projektstatus Multliselect-Filter oberhalb der Liste.
          </li>
          <li>
            Projekte: Auftraggeber Multliselect-Filter oberhalb der Liste.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.2" class="mb-4">
      <b-card-text> <small>05.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Neues Projekt: Speichern führt nicht zurück in die Projektliste sondern verbleibt in der
            Bearbeitungsmaske des Projektes.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.1" class="mb-4">
      <b-card-text> <small>03.05.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Dokumente: Falsche Textzuordnung bei "Weitere Dokumente"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.6.0" class="mb-4">
      <b-card-text> <small>30.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Neuer Tab "Techn. Prüfung"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.22" class="mb-4">
      <b-card-text> <small>29.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Intern: Zusätzliche Werte für Projekt-Status
          </li>
          <li>
            Projekt / Material: Tab vorübergehend entfernt
          </li>
          <li>
            Text: Installationsart "AN (Aufmaß)" gändert in "AM (Aufmaß)"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.21" class="mb-4">
      <b-card-text> <small>27.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Neue Spalte "Inst. Art" hinzugefügt
          </li>
          <li>
            Projekte: Spalte "Materialpl. fertig" entfernt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.20" class="mb-4">
      <b-card-text> <small>21.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt: Einige Felder reagieren erst beim Speichern auf Änderungen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.19" class="mb-4">
      <b-card-text> <small>20.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Anlage: Gesamt-Speicherkapazität (kWh) zeigt nun 3 Dezimalstellen an.
          </li>
          <li>
            Projekt / E-Mail: Textanpassung Vorlagen
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.18" class="mb-4">
      <b-card-text> <small>16.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Beim öffnen von Anhängen werden die ursprünglichen Dateinamen angezeigt und zum
            speichern verwendet.
          </li>
          <li>
            Wechselrichter: Eingabe Schein- und Wirkleistung mit Kommawerten möglich.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.17" class="mb-4">
      <b-card-text> <small>09.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Montage: Neues Auswahlfeld "Termin Inbetriebnahme"
          </li>
          <li>
            Elektrofachkraft: Neue Dokumententypen für Upload, "Unterschrift" und "Stempel"
          </li>
          <li>
            Elektrofachkraft: Neue Felder "Ausweisnummer" und "Eingetragen bei"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.16" class="mb-4">
      <b-card-text> <small>08.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            PDF-Editor: Neue Felder hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.15" class="mb-4">
      <b-card-text> <small>07.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            E-Mail-Versand: Zugangsdaten aktualisiert
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.14" class="mb-4">
      <b-card-text> <small>06.04.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: "Bilder Inbetriebnahme" zählt nicht mehr zu "Voraussetzungen
            Inbetriebnahmemeldung"
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Dokumente: 404 Fehler bei einigen Dokumenten
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.13" class="mb-4">
      <b-card-text> <small>23.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / Übersicht: "Projektnummer" umbenannt in "#"
          </li>
          <li>
            Projekt / Übersicht: Name EVU hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.12" class="mb-4">
      <b-card-text> <small>18.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Dokumente: Neuer Dokumententyp "Bilder Inbetriebnahme"
          </li>
          <li>
            Projekt / E-Mail: "Bilder Inbetriebnahme" werden der E-Mail "Inbetriebnahme" als Anhang
            angefügt
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte / Tourenplanung: Button-Farbe angepasst
          </li>
          <li>
            Projekte: Farbe Projekt-Status angepasst
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekte: Falsches Datum in Spalte "IN ges."
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.11" class="mb-4">
      <b-card-text> <small>12.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Montage: Neues Installationsteam "Team Ext."
          </li>
          <li>
            Projekt / Montage: Neue Installationsart "AN (Aufmaß)"
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.10" class="mb-4">
      <b-card-text> <small>12.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte und Tourenplanung: Neuer Button "Alle Filter löschen"
          </li>
          <li>
            Projekt: Neue Felder "Zählernummer 2" und "Zählernummer 3"
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Kommunikation: Datum für Netzanmeldung/Inbetriebnahme wird korrekt gesetzt
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.9" class="mb-4">
      <b-card-text> <small>10.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt kann nicht aufgerufen werden wenn kein EVU zugeordnet ist
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.8" class="mb-4">
      <b-card-text> <small>10.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / E-Mail: Auswahlmöglichkeit bei Netzanmeldung/Inbetriebnahmemeldung ob
            hochgeladene oder automatisch generierte Dokumente als Anhang versendet werden sollen.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.7" class="mb-4">
      <b-card-text> <small>09.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Generieren von Netzanmeldungen auf Knopfdruck. Nur für EVU's mit Freigabe ist diese
            Option aktiviert.
          </li>
          <li>
            Es werden PDF's für alle mit der EVU verknüpften Dokumente generiert.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.6" class="mb-4">
      <b-card-text> <small>05.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            EVU: "Vorlage Netzanmeldung" und "Vorlage Inbetriebnahme" nun mit gleicher
            Funktionalität wie "Dokumente".
          </li>
          <li>
            EVU: Allgemeine Dokumente können mit der Netzanmeldung und der Inbetriebnahmemeldung
            verknüpft werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.5" class="mb-4">
      <b-card-text> <small>03.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt / Kommunikation: Datum "Terminbestätigung gesendet" hinzugefügt
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Reduzierte Ladezeit
          </li>
          <li>
            Projekt / E-Mail: "Marktstammdatenregister" zu E-Mail-Text "Fehlende Unterlagen"
            hinzgefügt
          </li>
          <li>
            Tourenplanung / Export: Name EVU zum Export hinzugefügt
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.5.4" class="mb-4">
      <b-card-text> <small>01.03.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte / Export: Installationsart übersetzt
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Tourenplanung / Export: Korrektes Uhrzeit-Format bei Montagebeginn
          </li>
          <li>
            Projekte / Export: Datumswerte an Excel-Format angepasst
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.5.3" class="mb-4">
      <b-card-text> <small>26.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Dokumente: Neuer Menupunkt zum erstellen allgemeiner Formulare
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.5.2" class="mb-4">
      <b-card-text> <small>26.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            EVU: Neues Feld "Beschreibung", Anzeige auch im Projekt
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: "EWB" als Auftraggeber für neue Projekte voreingestellt
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: "Termin per E-Mail bestätigen" wählt nun die korrekte E-Mail Vorlage
            aus
          </li>
          <li>
            Projekt / "Termin per E-Mail bestätigen": Beim Versand der E-Mail geht der Termin nicht
            mehr verloren (Projekt muss trotzdem gespeichert werden um den Termin zu speichern!)
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <b-card title="Version 1.5.1" class="mb-4">
      <b-card-text> <small>24.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / E-Mail: Fehler beim senden von Netzanmeldungen
          </li>
          <li>
            Projekt / E-Mail: Netzanmeldungen und Inbetriebnahmemeldungen können trotz fehlender
            Vorraussetzungen verschickt werden.
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.5.0" class="mb-4">
      <b-card-text> <small>18.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Tourenplanung: Gefilterte Ansicht als xlsx exportieren
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.12" class="mb-4">
      <b-card-text> <small>16.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte: Anzeige Projektstatus
          </li>
          <li>
            Projekt: Neues Feld "Rechnungsnummer"
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.11" class="mb-4">
      <b-card-text> <small>14.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            EVU Editor: Feld "Wechselrichter Gesamtleistung"
          </li>
          <li>
            EVU Editor: Feld "Eingestellte Leistung am WR"
          </li>
          <li>
            EVU Editor: Feld "Messkonzept"
          </li>
          <li>
            EVU Editor: Feld "Auswahl" zum ankreuzen von Feldern
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.10" class="mb-4">
      <b-card-text> <small>14.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt: Vorauswahl erster Auftraggeber (alphabetisch)
          </li>
          <li>
            EVU: Editor "Vorlage Netzanmeldung" (Prototyp)
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.9" class="mb-4">
      <b-card-text> <small>13.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            EVU: Editor "Vorlage Netzanmeldung" (Prototyp)
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.8" class="mb-4">
      <b-card-text> <small>10.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            EVU: Editor "Vorlage Netzanmeldung" (Prototyp)
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.7" class="mb-4">
      <b-card-text> <small>09.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Projekt / Anlage: Speichersystem und Solarmodul gehen nicht mehr verloren (z.B. bei
            kurzzeitigen Verbindungsfehlern)
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.6" class="mb-4">
      <b-card-text> <small>08.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekt / E-Mail: Text für "Fehlende Unterlagen" angepasst.
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.5" class="mb-4">
      <b-card-text> <small>05.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte / Intern: Neues Datumsfeld "Rechnung vom Auftraggeber bezahlt"
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.4" class="mb-4">
      <b-card-text> <small>04.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte / Dokumente: "Bilder vom Auftraggeber" und "Montagedokumentation" werden als
            Bildergalerie dargestellt
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.3" class="mb-4">
      <b-card-text> <small>04.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte exportieren: Datumswerte ohne Uhrzeit werden auch im Export ohne Uhrzeit
            dargestellt
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.2" class="mb-4">
      <b-card-text> <small>02.02.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte: Übergabedatum in Projektliste
          </li>
          <li>
            Material bearbeiten: Buttonleiste wie bei Projekt bearbeiten
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.1" class="mb-4">
      <b-card-text> <small>29.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Gastzugang Auftraggeber: Alle Empfänger sichtbar in der Freigabemail
          </li>
          <li>
            Gastzugang Auftraggeber: Projektexport deaktiviert
          </li>
          <li>
            Projekte / Intern: Elektrofachkraft entfernt
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.4.0" class="mb-4">
      <b-card-text> <small>29.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Gastzugang für Auftraggeber. Benutzer mit der Berechtigung "Auftraggeber" können
            Projekte einsehen sowie Freigaben zu einem Projekt per E-Mail erteilen.
          </li>
          <li>
            Export: neues Feld "Anlage - Gesamtkapazität"
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.3.1" class="mb-4">
      <b-card-text> <small>29.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Geändert</h5>
        <ul>
          <li>
            Projekte/Dokumente: Text "Bilder Montage" in "Bilder vom Auftraggeber" geändert
          </li>
          <li>
            Tourenplanung: Bezeichnungen für Installationsart in Tabellenzeilen abgekürzt
          </li>
          <li>
            Tabellenfilter allgemein: Platzhaltertext aus Dropdownfeldern entfernt
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Liste Material: Filter arbeiten korrekt
          </li>
          <li>
            Alle Listen unter Anlagen: "Erstellt" und "Bearbeitet" werden korrekt angezeigt
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.3.0" class="mb-4">
      <b-card-text> <small>28.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Material: Editiermaske
          </li>
        </ul>
        <h5>Geändert</h5>
        <ul>
          <li>
            Liste Tourenplanung: Postleitzahlenfilter nach Anfangszahlen
          </li>
          <li>
            Liste Tourenplanung: Termin und Uhrzeit in eigener Spalte
          </li>
          <li>
            Liste Tourenplanung: Termin mit Filter "ab Datum"
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.2.0" class="mb-4">
      <b-card-text> <small>27.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekte / E-Mail: Inbetriebnahmemeldung an EVU verschicken
          </li>
          <li>
            Projekte / E-Mail: Freitext-E-Mail an Kunde verschicken
          </li>
          <li>
            Projekte / E-Mail: Terminbestätigung an Kunde verschicken
          </li>
        </ul>
        <h5>Fehlerbehebung</h5>
        <ul>
          <li>
            Dateiuploads: Fehler bei mehreren Uploads per drag & drop behoben. Ablagefläche für drag
            & drop vergrössert.
          </li>
          <li>
            Projekt: Alphabetische Sortierung in den Dropdownboxen Auftraggeber, Speichersystem,
            Solarmodul und EVU
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.1.0" class="mb-4">
      <b-card-text> <small>26.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>
            Projekt: Anforderungen für Netzanmeldung und für Inbetriebnahmemeldung werden per
            mouseover unter "Projektstatus" angezeigt.
          </li>
        </ul>
      </b-card-text>
    </b-card>

    <b-card title="Version 1.0.2" class="mb-4">
      <b-card-text> <small>25.01.2021</small></b-card-text>
      <b-card-text>
        <h5>Neu</h5>
        <ul>
          <li>Projekt / Material: Neues Feld "Materialplanung abgeschlossen"</li>
          <li>
            Projekt: Button "Speichern und zurück" führt zurück zur Ausgangsansicht (Liste
            Tourenplanung bzw. Liste Projekte)
          </li>
          <li>
            Projekt: Button "Abbrechen" führt zurück zur Ausgangsansicht (Liste Tourenplanung bzw.
            Liste Projekte)
          </li>
          <li>Liste Projekte: Neue Spalte "Materialpl. fertig" inkl. Filter</li>
          <li>Liste Tourenplanung: Neue Spalte "Materialpl. fertig" inkl. Filter</li>
          <li>
            Liste Tourenplanung: Filter-Einstellungen der Tabelle werden im Browser gespeichert
            (Local Storage)
          </li>
          <li>
            Liste Projekte: Filter-Einstellungen der Tabelle werden im Browser gespeichert (Local
            Storage)
          </li>
          <li>Neuer Menupunkt: Updates</li>
        </ul>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
  name: 'Changelog',
  components: { Breadcrumbs },
  computed: {
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('changelog'), route: { name: 'ChangelogPage' } },
      ];
    },
  },
};
</script>
